import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action"
import { NotFoundMeta } from "../data/seo-meta"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        description={NotFoundMeta.description}
        path={NotFoundMeta.path}
        title={NotFoundMeta.title}
      />
      <div className="container py-72">
        <h1 className="h1 pb-8">Oops! That page can’t be found.</h1>
        <p className="p1">
          Thanks for your patience while we put the pieces back together.
          <br />
          <br />
          <CallToAction label="Back to Slice Home" to="/" />
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
